import { useState } from "react";
import "./App.css";
import "bulma/css/bulma.css";

function App() {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  function handleChange(value) {
    setQuery(value);
    value = value.trim();
    if (value.length === 0) {
      setSuggestions([]);
    }
  }

  async function handleKeyUp(value) {
    value = value.trim();
    if (value.length > 0) {
      const promise = await fetch(
        `/.netlify/functions/shahnameh_lookup?name=${value}`
      );
      const json = await promise.json();
      setSuggestions(json);
    } else {
      setSuggestions([]);
    }
  }

  return (
    <div className="container is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
      <div
        id="container"
        className="column is-flex is-justify-content-center is-align-items-center is-flex-direction-column"
      >
        <p>
          <img src="shahnameh.png" width={500} alt="shahnameh" />
        </p>
        <br></br>
        <h3 id="site-title">Shahnameh Name Lookup</h3>
        <br></br>
        <input
          value={query}
          onChange={(e) => handleChange(e.target.value)}
          onKeyUp={(e) => handleKeyUp(e.target.value)}
          className="input"
          type="text"
          placeholder="Start typing names"
        />
      </div>

      <ul
        id="suggestions"
        className="is-flex is-flex-direction-column is-text-align-left"
      >
        {suggestions.map((value) => (
          <li className="is-flex-grow-1">{value}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;
